<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <exports-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></exports-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
const ExportsDataTable = () => import('@/components/ExportsDataTable.vue')
import { isMobileDevice } from '@/helpers/utilities.js'

export default {
    components: {
        ExportsDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        dataTableSettings: {
            id: 'dtExports',
            title: 'Seznam dokumentov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Naziv dokumenta", value: "name", class: "text-xs-left", visibility: true},
                { text: "Status", value: "export_status_name", class: "text-xs-left", visibility: true},
                { text: "Tip datoteke", value: "file_type_for_humans", class: "text-xs-left", visibility: true},
                { text: "Velikost datoteke", value: "file_size_readable", class: "text-xs-left", visibility: true},
                { text: "Trajanje", value: "execution_time_for_humans", class: "text-xs-left", visibility: true},
                { text: "Skupina podatkov", value: "group", class: "text-xs-left", visibility: true},
                { text: "Dodal (uporabnik)", value: "user", class: "text-xs-left", visibility: true},
                { text: "Čas izvoza", value: "created_at_formatted", class: "text-xs-left", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true, permissions:[]},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'dolina-soce/exports',
            downloadItem: true,
            viewItem: {
                route: 'view_user',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_FILE',
                replaceStrWithColumnValue: 'name',
                routeParameterValue: 'uuid',
                confirmationText : "Sta prepričani da želite odstraniti dokument {str_to_replace} ?",
                dialogConfirmSuccessText: 'Dokument {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju dokumenta {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: null,
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_exports',
                totalItems : {
                    event: ''
                },
                search: 'dt-exports-search',
                toggleColumnsId: 'toggle-exports-list-columns'
            }


        }
    }),

    computed: {
        queryParameters() {
            return {
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null
            }
        },
    },

    methods: {
        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        displayResults() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 280)
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
        // window.console.log("### Exports@created ###");

        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_exports",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off(this.dataTableSettings.id)
    }
}

</script>